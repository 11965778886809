import Vue from 'vue'
import VueRouter from 'vue-router'

import jsCookie from 'js-cookie'
const cookies = jsCookie; 
Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'index',
        component: () => import('@/views/index'),
        redirect:'/home',
        meta: {
            title: '嗨数 | 电商黑名单'
        }
    },
    {
        path: '/index',
        component: () => import('@/views/index'),
        meta: {
            title: '嗨数 | 电商黑名单'
        },
        children: [
            {
                path: '/home',
                name: 'home',
                component: () => import('@/views/home'),
                meta: {
                    title: '嗨数 | 电商黑名单-首页'
                }
            },
        ]
    },
    {
        path: '*',
        component: () => import('@/views/404.vue'),
        redirect: '/404'
    },
]



const router = new VueRouter({
    mode: 'history',
    routes
})
//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
router.beforeEach((to, from, next) => {
    /* 路由发生变化修改页面title */
    if(to.meta.title) {
        document.title = to.meta.title
    }
    //1.如果用户访问/login的请求,应该直接放行
    // if(to.path === '/login' || to.path === '/permission') return next()

    //2.不是访问的登录页面,所以判断用户是否登录. 判断依据token
    // let token = cookies.get('user');
    //3.如果!token  没有值,则执行if之后的操作
    // if(!token)  return next("/login")

    //4.如果代码执行到这一行,说明用户已经登录.则放行
    next()
})


export default router
