import { thirId,logout,changePass,changeMobile,getAccountInfos,getUserInfo,mobile, sendVerifyCode,checkVerifyCode,getQrcodeTicket,checkSubscribe, account } from '@/api/usercenter'
import { getToken } from '@/utils/auth'

const state = {
    token: getToken()
}
const actions = {
    // 绑定手机号
    thirId({ commit, state }, form) {
        return new Promise((resolve, reject) => {
            thirId(form, state.token)
                .then(res => {
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    logout({ commit, state }, form) {
        return new Promise((resolve, reject) => {
            logout(form, state.token)
                .then(res => {
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    //更换密码
    changePass({ commit, state }, form) {
        return new Promise((resolve, reject) => {
            changePass(form, state.token)
                .then(res => {
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    //更换绑定手机
    changeMobile({ commit, state }, form) {
        return new Promise((resolve, reject) => {
            changeMobile(form, state.token)
                .then(res => {
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    // 获取用户信息
    getAccountInfos({ commit, state }, form) {
        return new Promise((resolve, reject) => {
            getAccountInfos(form, state.token)
                .then(res => {
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    // 获取用户信息
    getUserInfo({ commit, state }, form) {
        return new Promise((resolve, reject) => {
            getUserInfo(form, state.token)
                .then(res => {
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
     // 登录
     account({ commit, state }, form) {
        return new Promise((resolve, reject) => {
            account(form, state.token)
                .then(res => {
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    // 登录
    mobile({ commit, state }, form) {
        return new Promise((resolve, reject) => {
            mobile(form, state.token)
                .then(res => {
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    // 获取用户信息
    checkVerifyCode({ commit, state }, form) {
        return new Promise((resolve, reject) => {
            checkVerifyCode(form, state.token)
                .then(res => {
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    // 获取验证码
    sendVerifyCode({ commit, state }, form) {
        return new Promise((resolve, reject) => {
            sendVerifyCode(form, state.token)
                .then(res => {
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
     // 获取二维码
     getQrcodeTicket({ commit, state }, form) {
        return new Promise((resolve, reject) => {
            getQrcodeTicket(form, state.token)
                .then(res => {
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
     // 检测是否扫码
     checkSubscribe({ commit, state }, form) {
        return new Promise((resolve, reject) => {
            checkSubscribe(form, state.token)
                .then(res => {
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
}

export default {
    namespaced: true,
    state,
    actions
}
