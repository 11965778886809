import { attachment,search, searchStatic,singleImport,picImport,infoImport,info} from '@/api/bp'
import { getToken } from '@/utils/auth'

const state = {
    token: getToken()
}
const actions = {
    //下载附件
    attachment({ commit, state }, form) {
        return new Promise((resolve, reject) => {
            attachment(form, state.token)
                .then(res => {
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    //单条风险
     info({ commit, state }, form) {
        return new Promise((resolve, reject) => {
            info(form, state.token)
                .then(res => {
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    //信息确认入库
    infoImport({ commit, state }, form) {
        return new Promise((resolve, reject) => {
            infoImport(form, state.token)
                .then(res => {
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    // 风险查询
    search({ commit, state }, form) {
        return new Promise((resolve, reject) => {
            search(form, state.token)
                .then(res => {
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    // 风险查询图表
    searchStatic({ commit, state }, form) {
        return new Promise((resolve, reject) => {
            searchStatic(form, state.token)
                .then(res => {
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    //手动入库
    singleImport({ commit, state }, form) {
        return new Promise((resolve, reject) => {
            singleImport(form, state.token)
                .then(res => {
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    //图片录入
    picImport({ commit, state }, form) {
        return new Promise((resolve, reject) => {
            picImport(form, state.token)
                .then(res => {
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    
}

export default {
    namespaced: true,
    state,
    actions
}
