import jsCookie from 'js-cookie'

const TokenKey = 'aaa'
export function getToken() {
    return jsCookie.get(TokenKey)
}

export function setToken(token) {
    return jsCookie.set(TokenKey, token)
}

export function removeToken() {
    return jsCookie.remove(TokenKey)
}   