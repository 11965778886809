import request from "@/utils/request";

// 风险查询
export function search(data) {
    return request({
        url: '/bp/search',
        method: 'post',
        data
    })
}

// 风险查询折线图
export function searchStatic(data) {
    return request({
        url: '/bp/searchStatic',
        method: 'post',
        data
    })
}
// 手动入库
export function singleImport(data) {
    return request({
        url: '/bp/singleImport',
        method: 'post',
        data
    })
}
// 图片入录
export function picImport(data) {
    return request({
        url: '/bp/picImport',
        method: 'post',
        data
    })
}

//信息确认入库
export function infoImport(data) {
    return request({
        url: '/bp/infoImport',
        method: 'post',
        data
    })
}

//单条风险
export function info(data) {
    return request({
        url: '/bp/info',
        method: 'post',
        data
    })
}
//下载附件
export function attachment(data) {
    return request({
        url: '/bp/attachment',
        method: 'get',
        params: data
    })
}
