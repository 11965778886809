function getUUid(length = 32) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for(let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    console.log('getUUid', result);
    return result;
}
function decode(str) {
    var result = str;
    if(arguments.length > 1) {
        if(arguments.length == 2) {
            result = args;
        } else {
            for(var i = 1; i < arguments.length; i = i + 2) {
                if(arguments[i] != undefined) {
                    if(arguments[i + 1] != undefined) {
                        if(result == arguments[i]) {
                            result = arguments[i + 1];
                            break;
                        }
                    } else {
                        result = arguments[i];
                    }
                }
            }
        }
    }
    return result;
}
export default {
    getUUid,
    decode
}

